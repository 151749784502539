import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { browserName, deviceDetect, deviceType } from 'react-device-detect';

import BannerImg from "../../components/bannerImg";
import {
  ContentWrapper,
  OnboardingWrapper,
  BannerWrapper,
  BottomCWrapper,
  Description,
  Heading,
  HeadingText1,
  LeftContent,
  RightContent,
  NextBtn,
  MoveBtn,
  UtilitiesBtnContainer,
  UtilitiesSubContainer,
  UtilityText,
} from "./styles";
import {
  screenWidth,
  maximumTabletWidth,
  getRandomImage,
  generateInitialState,
} from "../../utils";
import { appImages } from "../../themes/imageConstant";
import { appColors } from "../../themes/colorConst";

import { useAnimation } from "framer-motion";
import Step1 from "../onboarding/step1";
import GeneratingWord from "./generatingWord";
import Game from "./game";
import CustomKeyboard from "../../components/customKeyboard";
import AnchorTemporaryDrawer from "../result";
import Result from "../result";
import PopoverCommon from "../../components/popover";
import YourPerformance from "../yourPerformance";
import BotSolutions from "../botSolutions";
import { useDispatch, useSelector } from "react-redux";
import {
  botStepsInitiate,
  getProfileInitiate,
  getWordInitiate,
  isUserExistInitiate,
  updateGamePlayStates,
  userEventsInitiate,
  validateWordInitiate,
  viewSolutionClickInitiate,
  yourPerformanceInitiate,
  yourResultInitiate,
} from "../../redux/actions/game";
import Loader from "../../components/loader";
import CompletedGame from '../../screens/finalSolution'
import useAnalyticsEventTracker from "../../useAnalyticsEventTracker";
import ModalForUtilities from "../../components/modalForUtilities";
import ModalShare from "../../components/modalShare";

const opacityDuration = 1;

const GamePlay = () => {
  const dispatch = useDispatch();

  const gaEventTrackerForRsult = useAnalyticsEventTracker('Total Steps');
  const gaEventTrackerForTotalGamesCount = useAnalyticsEventTracker('Total Game Solved');
  const gaEventTrackerForTotalGamesPlayedCount = useAnalyticsEventTracker('Total Games Played');
  const gaEventTrackerForPerformance = useAnalyticsEventTracker('Performance');

  const bannerImageAnimate = useAnimation()
  const actionTitleMessageContent = useAnimation()

  const reducerStates = useSelector((state) => state.game);
  const {
    wordLoader,
    words,
    wordSuccess,
    validateWordSuccess,
    validateWordLoader,
    validateResponse,
    yourResultLoader,
    yourResultSuccess,
    yourResult,
    botStepsLoader,
    botStepsSuccess,
    botSteps,
    profileDataLoader,
    profileDataSuccess,
    profileData,
    botStepsCount,
    challengeDifficulty,
    gameCompleted,
    gameData,
    getFirstGame,
    isUserExistSuccess,
    isUserExistLoader,
    wordId,
    viewSolutionClickLoader,
    viewSolutionClickSuccess,
    viewSolution,
    viewSolutionForWord
  } = reducerStates;

  const bannerImageForGenerateWord = [
    appImages.gameBanner1,
    appImages.gameBanner2,
    appImages.gameBanner3,
    appImages.gameBanner4,
    appImages.gameBanner5,
    appImages.gameBanner6,
    appImages.gameBanner7,
  ];

  const randomImage = getRandomImage(bannerImageForGenerateWord);

  const [bannerImage, setBannerImage] = React.useState(randomImage.randomImage);
  const [loadedImage, setLoadedImage] = React.useState(null);
  const [statusBarColor, setStatusBarColor] = React.useState(randomImage.randomColor);
  const [attempts, setAttempts] = React.useState([]);
  const [input, setInput] = useState("");
  const [temporaryWordIndex, setTemporaryWordIndex] = useState(-1);
  const [temporaryWord, setTemporaryWord] = useState("");
  const [temporaryMixPrevArr, setTemporaryMixPrevArr] = useState([]);
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  const [isWordLocked, setIsWordLocked] = useState(false);
  const [enabledKeys, setEnabledKeys] = useState([]);
  const [action, setAction] = useState([
    {
      add: {
        step: 0,
        isActive: false,
      },
      remove: {
        step: 0,
        isActive: false,
      },
      change: {
        step: 0,
        isActive: false,
      },
      mixup: {
        step: 0,
        isActive: false,
      },
    },
  ]);
  const [word, setWord] = useState([]);
  const [wordCopyForUndo, setWordCopyForUndo] = useState([]);
  const [finalWord, setFinalWord] = useState([]);
  const [initialWord, setInitialWord] = useState([]);
  const [activeWordIndex, setActiveWordIndex] = useState(0);
  const [selectedFilter, setSelectedFilter] = React.useState('All');

  // Result Screen States
  const [isResultVisible, setIsResultVisible] = useState(false);

  // Performance screen States
  const [isPerformanceVisible, setIsPerformanceVisible] = useState(false);

  // Bot Solution screen States
  const [isBotSolutionVisible, setIsBotSolutionVisible] = useState(false);

  //Action button change background
  const [actionBtnColor1, setActionBtnColor1] = React.useState(appColors.whiteColor);
  const [actionBtnColor2, setActionBtnColor2] = React.useState(appColors.whiteColor);
  const [actionBtnColor3, setActionBtnColor3] = React.useState(appColors.whiteColor);
  const [actionBtnColor4, setActionBtnColor4] = React.useState(appColors.whiteColor);

  // Utilites
  const [isUtilsModalVisible, setIsUtilsModalVisible] = useState(false);
  const [utilsModalData, setUtilsModalData] = useState(null);

  //Share with firends
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);

  const animateBannerImage = async () => {
    bannerImageAnimate.mount()
    bannerImageAnimate.start({
      background: `url(${bannerImage})!important`
    })
  }

  const animate = useCallback( async () => {
   console.log("this is coming animate = ", gameData)
   console.log("this is coming animate = ", viewSolutionForWord)
    await new Promise((resolve) => setTimeout(resolve, 1 * (gameData == null ? 4500 : 0)));
    actionTitleMessageContent.mount()

    await Promise.all([
      actionTitleMessageContent.start({
        opacity: 1,
        transition: { delay: viewSolutionForWord ? 0 : 3.5, duration: 0.2, ease: 'easeOut' },
      })
    ])
    actionTitleMessageContent.stop()
  },[gameCompleted, viewSolutionForWord])

  useEffect(() => {
    // animateBannerImage()
    
    // if (gameData == null) {
        animate()
    // }
    return () => {
      actionTitleMessageContent.stop()
    }
  }, [gameCompleted, viewSolutionForWord]);

  useLayoutEffect(() => {
    const metaThemeColor = document.querySelector('meta[name=theme-color]');
    if (metaThemeColor) {
      if ((isPerformanceVisible || isResultVisible)) {
        metaThemeColor.setAttribute('content', `black`);
      } else if (!gameCompleted) {
        metaThemeColor.setAttribute('content', `${statusBarColor}`);
      }
    }
  }, [isPerformanceVisible, isResultVisible, gameCompleted, statusBarColor]);

  useLayoutEffect(() => {
    if (!gameCompleted) {
      setTimeout(() => {
        dispatch(getWordInitiate());

      }, 3000)
    } else {
      dispatch(getWordInitiate());
    }
    return () => {
      setIsPerformanceVisible(false)
      setIsResultVisible(false)
      setIsBotSolutionVisible(false)
      dispatch(updateGamePlayStates(false, 'validateWordSuccess'))
      dispatch(updateGamePlayStates(false, 'botStepsSuccess'))
      dispatch(updateGamePlayStates(null, 'validateResponse'))
      dispatch(updateGamePlayStates(null, 'words'))
    }
  }, []);

  // // this is for session maintain
  // useLayoutEffect(() => {
  //   if (isUserExistSuccess && gameData) {
  //     setWord(gameData?.currentWord)
  //     setWordCopyForUndo(gameData?.currentWord)
  //     // setAttempts(gameData?.wordData)
  //   }
  // }, [isUserExistLoader])

  useEffect(() => {
    dispatch(getProfileInitiate({ filter: selectedFilter }))
  }, [selectedFilter,])

  useEffect(() => {
    if (words && wordSuccess && !wordLoader) {
      // /* this is start of dummy block */

      // const data = {
      //   count: botStepsCount,
      //   attempt: attempts?.length || 0,
      //   finalWord: words?.final?.join("").toLowerCase() || "",
      //   initialWord: words?.initial?.join("").toLowerCase() || "",
      //   currentWord: words?.final?.join("").toLowerCase() || "",
      // };
      // dispatch(yourResultInitiate(data));
      // handleVisibleResultScreen();
      // /* this is the end of dummy block */
      if (viewSolutionForWord) {
        localStorage.setItem('isGamePlayed', true)
      }
      if (gameData) {
        console.log("here is gamedata = ", gameData, "here is attempts = ", attempts, "viewSolutionForWord = ", viewSolutionForWord, "words?.initial = ", words)
        
        // here i added attempts last index for view soultion preserve last word
        if (viewSolutionForWord) {
          setWord(words?.initial);
          setWordCopyForUndo(words?.initial);
          setAttempts([])
        } else {
          setWord(gameData?.currentWord || gameData?.initialWord);
          setWordCopyForUndo(gameData?.currentWord || gameData?.initialWord);
          setAttempts(gameData?.wordData)
        }
        
      } else {
        console.log("here is gamedata = ", gameData?.currentWord, "here is attempts = ", attempts?.length > 0 ? attempts[attempts.length - 1] : words?.initial)
        console.log("here is gamedata = ", viewSolution, "here is attempts = ", viewSolution ? word : words?.initial)
        console.log("here is gamedata = ", viewSolution, "here is attempts = ", viewSolutionForWord, "viewSolutionForWord = ",  word , "viewSolutionForWord initial = ", words?.initial)
        setWord(attempts?.length > 0 ? attempts[attempts.length - 1] : words?.initial);
        setWordCopyForUndo((viewSolution) ? word : words?.initial);
      }

      setFinalWord(words?.final);
      setInitialWord(words?.initial);
      localStorage.setItem('botSteps', JSON.stringify(botSteps))
    }
  }, [words]);

  useEffect(() => {
    // view solution screen visible logic

    if (!botStepsLoader && botStepsSuccess) {
      setIsBotSolutionVisible(true);
      updateGamePlayStates(false, "botStepsSuccess");
      // updateGamePlayStates(false, "viewSolutionClickSuccess");
    }
  }, [botStepsLoader]);

  useEffect(() => {
    if (!profileDataLoader && profileDataSuccess) {
      dispatch(updateGamePlayStates(false, 'profileDataSuccess'))
    }
  }, [profileDataLoader]);

  useEffect(() => {
    // hit the result Api after complition of word

    if (validateResponse?.data?.isCompleted) {
      const data = {
        count: botStepsCount,
        attempt: attempts?.length || 0,
        finalWord: finalWord?.join("").toLowerCase() || "",
        initialWord: initialWord?.join("").toLowerCase() || "",
        currentWord: word?.join("").toLowerCase() || "",
      };
      dispatch(yourResultInitiate(data));
      dispatch(updateGamePlayStates(null, 'gameData'))
    }
  }, [validateResponse]);

  useEffect(() => {
    if (action[0]["mixup"]["isActive"]) {
      let tempEnabledKeys = [...temporaryMixPrevArr, "⌫"];
      setEnabledKeys(tempEnabledKeys);
    }
  }, [action]);

  const handleActionClick = (type) => {
    setIsKeyboardVisible(false)

    // set ActionBtn BackgroundColor
    setActionBtnColor1(
      (Object.keys(action[0])[0] === type)
        ?
        action[0]['add']["isActive"] ? appColors.whiteColor : appColors.greyBtnColor
        :
        appColors.whiteColor
    )
    setActionBtnColor2(
      (Object.keys(action[0])[1] === type)
        ?
        action[0]['remove']["isActive"] ? appColors.whiteColor : appColors.greyBtnColor
        :
        appColors.whiteColor
    )
    setActionBtnColor3(
      (Object.keys(action[0])[2] === type)
        ?
        action[0]['change']["isActive"] ? appColors.whiteColor : appColors.greyBtnColor
        :
        appColors.whiteColor
    )
    setActionBtnColor4(
      (Object.keys(action[0])[3] === type)
        ?
        action[0]["mixup"]["isActive"] ? appColors.whiteColor : appColors.greyBtnColor
        :
        appColors.whiteColor
    )
    /** ACTUAL LOGIC START FROM HERE */
    const updatedAction = action.map((item, index) => {
      // Initialize a new object to store the updated state for the current item
      const updatedItem = {};

      // Iterate over each key in the current item
      Object.keys(item).forEach((key) => {

        // Set isActive to true for the clicked button and false for others
        updatedItem[key] = {
          ...item[key],
          isActive: key === type ? !item[type]["isActive"] : false,
          step: key === type ? (item[type]["isActive"] ? 0 : 1) : 0, // Set step to 1 for the clicked button
        };
      });

      return updatedItem;
    });

    setAction(updatedAction);
    if (type == "mixup" && !action[0]["mixup"]["isActive"]) {
      let createNewArr = Array(wordCopyForUndo.length).fill("");
      setActiveWordIndex(0);
      setTemporaryMixPrevArr(wordCopyForUndo);
      setWord(createNewArr);
      setIsKeyboardVisible(true);
    }
  };

  useEffect(() => {
    // Remove IsValidate or not validate Message screen.

    if (isWordLocked) {
      setTimeout(() => {
        if (validateResponse?.message == "Invalid Word. Try Again") {
          if (attempts && attempts.length > 0) {
            setWordCopyForUndo(attempts[attempts.length - 1]);
            setWord(attempts[attempts.length - 1]);
          } else {
            console.log("this is the prev locked = ", words?.initial)
            setWordCopyForUndo(words?.initial);
            setWord(words?.initial);
          }
        }
        setIsWordLocked(false);
        setTemporaryWordIndex(-1);
        setTemporaryWord("")
      }, 3000);
    }
  }, [isWordLocked]);

  useEffect(() => {
    if (!validateWordLoader && validateWordSuccess) {
      if (validateResponse?.message !== "Invalid Word. Try Again") {
        let tempAttemptsArr = [...attempts];
        tempAttemptsArr.push(word);
        setAttempts(tempAttemptsArr);
      }
      setIsWordLocked(true);
      const updatedAction = action.map((item) => {
        if (validateResponse?.data?.action in item) {
          return {
            ...item,
            [validateResponse?.data?.action]: {
              ...item[validateResponse?.data?.action],
              isActive: false,
              step: 0,
            },
          };
        }
        return item;
      });

      // this is for remove active button color only
      if (validateResponse?.data?.action == 'add') {
        setActionBtnColor1(
          action[0]["add"]["isActive"] ? appColors.whiteColor : appColors.greyBtnColor
        )
      } else if (validateResponse?.data?.action == 'remove') {
        setActionBtnColor2(
          action[0]["remove"]["isActive"] ? appColors.whiteColor : appColors.greyBtnColor
        )
      } else if (validateResponse?.data?.action == 'change') {
        setActionBtnColor3(
          action[0]["change"]["isActive"] ? appColors.whiteColor : appColors.greyBtnColor
        )
      } else {
        setActionBtnColor4(
          action[0]["mixup"]["isActive"] ? appColors.whiteColor : appColors.greyBtnColor
        )
      }
      // end of remove active button color only

      setAction(updatedAction);
    }
  }, [validateWordLoader]);

  useEffect(() => {
    // Logic for display the result after complition
    if (validateResponse?.data?.isCompleted) {
      if (yourResultSuccess && !yourResultLoader) {
        setTimeout(() => {
          if (localStorage.getItem('isGamePlayed') == "false") {
            handleVisibleResultScreen();
            console.log("this is change count trigger")
            gaEventTrackerForRsult(`${initialWord.join("")}_${finalWord.join("")}`, `${yourResult?.attempt}_steps`, 'total_count')
            gaEventTrackerForTotalGamesCount(`${initialWord.join("")}_${finalWord.join("")}`, "Users_Game_Completed", 'total_count')
          } else {
            dispatch(updateGamePlayStates(true, 'gameCompleted'))
          }
          setAttempts([]);
          // setTemporaryWord("")
          setWord(["S", "L", "I", "C", "E"]);
        }, 500);
        dispatch(getProfileInitiate({ filter: selectedFilter }))
      }
    }
  }, [yourResultLoader]);

  const handleWordLocked = (activeActionName) => {
    const data = {
      finalWord: finalWord?.join("").toLowerCase() || "",
      initialWord: initialWord?.join("").toLowerCase() || "",
      currentWord: word?.join("").toLowerCase() || "",
      attempt: attempts?.length || 0,
      action: activeActionName,
      previousWord:
        attempts && attempts.length > 0
          ? attempts[attempts.length - 1].join("").toLowerCase()
          : null,
      challengeDifficulty: challengeDifficulty
    };

    setWordCopyForUndo(word);
    dispatch(validateWordInitiate(data));
    if (attempts && attempts.length == 0) {
      // console.log("this is gameData = ", initialWord.join(""))
      gaEventTrackerForTotalGamesPlayedCount(`${initialWord.join("")}_${finalWord.join("")}`, 'users_played', 'total_count')
    }
  };

  /** Add Features */
  const handleAddClick = (type, index) => {
    const updatedAction = action.map((item) => {
      if (type in item) {
        return { ...item, [type]: { ...item[type], step: 2 } };
      }
      return item;
    });

    let tempWordArr = [...word];

    tempWordArr.splice(index, 0, "");
    setWord(tempWordArr);
    setAction(updatedAction);
    setIsKeyboardVisible(true);
  };

  const handleRemoveEmptyAddInput = () => {
    let tempWordArr = word.filter(Boolean);
    // setWordCopyForUndo(word);
    setWord(tempWordArr);
  };

  const handleAddKeyboardChange = (key) => {
    let tempWordArr = [...word];
    const emptyStringIndex = word.indexOf("");
    tempWordArr[emptyStringIndex] = key;
    setTemporaryWordIndex(emptyStringIndex);
    // setWordCopyForUndo(word);
    setWord(tempWordArr);
  };

  const handleUndoWord = (activeActionName) => {
    console.log("this is the prev data = ", wordCopyForUndo)
    setWord(wordCopyForUndo);
    setTemporaryWordIndex(-1);
    if (activeActionName) {
      setAction(generateInitialState());

      // this is for remove active button color only
      if (action[0]["add"]["isActive"]) {
        setActionBtnColor1(
          appColors.whiteColor
        )
      } else if (action[0]["remove"]["isActive"]) {
        setActionBtnColor2(
          appColors.whiteColor
        )
      } else if (action[0]["change"]["isActive"]) {
        setActionBtnColor3(
          appColors.whiteColor
        )
      } else {
        setActionBtnColor4(
          appColors.whiteColor
        )
      }
      // end of remove active button color only
    }
  };

  /** Remove Features */

  const handleRemoveClick = (type, index, item) => {
    const updatedAction = action.map((item) => {
      if (type in item) {
        return { ...item, [type]: { ...item[type], step: 2 } };
      }
      return item;
    });

    let tempWordArr = [...word];

    tempWordArr.splice(index, 1);
    setTemporaryWord(item);
    setTemporaryWordIndex(index);
    setWord(tempWordArr);
    setAction(updatedAction);
  };

  /** Change Feautures */
  const handleChangeClick = (type, index, item) => {
    const updatedAction = action.map((item) => {
      if (type in item) {
        return { ...item, [type]: { ...item[type], step: 2 } };
      }
      return item;
    });

    let tempWordArr = [...word];

    // this is for stop multi word changes in one time
    if (temporaryWord) {
      let emptyIndex = word.indexOf('');
      // console.log("this is check = ", temporaryWord, emptyIndex)
      emptyIndex >= 0 && tempWordArr.splice(emptyIndex, 1, temporaryWord)
    }

    tempWordArr.splice(index, 1, "");
    // setWordCopyForUndo(word)
    setWord(tempWordArr);
    setAction(updatedAction);
    setTemporaryWord(item);
    // setTemporaryWordIndex(index)
    setIsKeyboardVisible(true);
  };

  const handleInitializeChangeAction = () => {
    let tempWordArr = [...word];
    const emptyStringIndex = word.indexOf("");
    tempWordArr[emptyStringIndex] = temporaryWord;
    setTemporaryWord("");
    setWord(tempWordArr);
    setIsKeyboardVisible(false);
  };

  /** Mixup Feautures */

  const handleMixupKeyboardChange = (key) => {
    let tempWordArr = [...word];
    const emptyStringIndex = word.indexOf("");
    setActiveWordIndex(emptyStringIndex + 1);
    tempWordArr[emptyStringIndex] = key;

    // Find the index of the first occurrence of the element
    let tempMixupPrevArr = [...enabledKeys]
    let index = tempMixupPrevArr.indexOf(key);
    // Check if the element is found in the array
    if (index !== -1) {
      // Remove the element at the found index
      tempMixupPrevArr.splice(index, 1);
      setEnabledKeys(tempMixupPrevArr)
    }

    if (emptyStringIndex == word.length - 1) {
      setTemporaryWordIndex(emptyStringIndex);
      setIsKeyboardVisible(false);
    }
    setWord(tempWordArr);
  };

  const handleInitializeMixupAction = () => {
    let tempWordArr = [...temporaryMixPrevArr];
    setTemporaryMixPrevArr([]);
    setTemporaryWord("");
    setWord(tempWordArr);
    setIsKeyboardVisible(false);
  };

  const handleBackSpaceClick = () => {
    let tempWord = [...word];
    for (let i = tempWord.length - 1; i >= 0; i--) {
      // Check if the element is a non-empty string
      if (typeof tempWord[i] === "string" && tempWord[i] !== "") {

        // update enabledKeys array when user click on backspace
        let tempEnabledKeysArr = [...enabledKeys]
        tempEnabledKeysArr.push(tempWord[i])
        setEnabledKeys(tempEnabledKeysArr)

        // Replace the value of the last non-empty string element
        setActiveWordIndex((prev) => prev - 1);
        tempWord[i] = "";
        break; // Stop iterating after updating the last non-empty string element
      }
    }
    setWord(tempWord);
  };

  const handleKeyPress = (key) => {
    if (key === "backspace") {
      setInput(input.slice(0, -1));
      if (action[0]["mixup"]["isActive"]) {
        handleBackSpaceClick();
      }
    } else {
      if (!action[0]["mixup"]["isActive"]) {
        setIsKeyboardVisible(false);
        handleAddKeyboardChange(key);
      } else {
        //  Add logic
        handleMixupKeyboardChange(key);
      }
    }
  };

  // Result Screen Features
  const handleVisibleResultScreen = () => {
    setIsResultVisible(!isResultVisible);
  }

  // Performance Screen Features
  const handleVisiblePerformanceScreen = () => {
    dispatch(getProfileInitiate({ filter: selectedFilter}));
    gaEventTrackerForPerformance(`Performance_Clicks`, 'performance_clicks', 'total_count')
    dispatch(userEventsInitiate({ type: 'performance' }))
    setIsPerformanceVisible(!isPerformanceVisible);
    // setIsPerformanceVisible(!isPerformanceVisible)
  };

  // Bot Solution Features
  const handleVisibleBotSolutionScreen = () => {
    const data = {
      finalWord: finalWord?.join("").toLowerCase() || "",
      initialWord: initialWord?.join("").toLowerCase() || "",
      currentWord: word?.join("").toLowerCase() || "",
      attempt: attempts?.length || 0,
      previousWord:
        attempts && attempts.length > 0
          ? attempts[attempts.length - 1].join("").toLowerCase()
          : null,
    };
    dispatch(botStepsInitiate(data));
    // setIsBotSolutionVisible(!isBotSolutionVisible)
  };

  // Change performance filter value
  const handleChangeFilterValue = (val) => {
    setSelectedFilter(val)
  }

  const getBannerImageHeight = () => {
    if (temporaryWordIndex >= 0) {
      return browserName === "Mobile Safari" ? "100%" : "95%";
    }
    if (attempts?.length > 1) {
      return browserName === "Mobile Safari" ? "95%" : "90%";
    }
    return browserName === "Mobile Safari" ? "85%" : "false";
  };

  //utilitesPopup

  const handleOpenUtilsModal = (value) => {
    setUtilsModalData(value)
    setIsUtilsModalVisible(true);
  }

  const handleCloseModal = () => {
    setIsUtilsModalVisible(false);
    setUtilsModalData(null)
    // if (utilsModalData == 'restart') {

    // } else {
    //   dispatch(userEventsInitiate({ type: 'viewFalse' }))
    // }
  }

  // View Solution Events
  const handleViewSolutionClick = () => {
    console.log("this is the prev set = ", word)
    setWord(words?.initial)
    setAttempts([])
    setWordCopyForUndo(word)
    setTemporaryWord("")
    setTemporaryWordIndex(-1)
    // 
  }

  // Handle restart game only

  const handleRestartGame = () => {
    setWordCopyForUndo(words?.initial)
    setAttempts([])
    setWord(words?.initial);
  }

  // Handle Share with firends

  const handleCloseShareModal = () => {
    setIsShareModalVisible(false)
  }

  const handOpenShareModal = () => {
    setIsShareModalVisible(true)
  }

  return (
    <>
      {(!gameCompleted && !viewSolution) ?
        <OnboardingWrapper
          cstmheight={
            browserName == "Mobile Safari"
              ?
              (temporaryWordIndex >= 0 ? "96vh" : "90vh")
              :
              (browserName == "Chrome" && deviceType == "mobile")
                ?
                (temporaryWordIndex >= 0 ? "96vh" : "90vh")
                :
                "100vh"
          }
        >

          <ContentWrapper id={"contentContainer"}>
            <BannerWrapper
              key={bannerImage}
              // background={bannerImage}
              initial={{ background: `url(${bannerImage})`, backgroundPosition: screenWidth > 360 ? "bottom" : "top", backgroundRepeat: 'no-repeat', backgroundSize: 'cover', transition: 'height 0.1s ease-in-out' }}
              animate={bannerImageAnimate}
              backgroundposition={screenWidth > 360 ? "bottom" : "top"}
              cstmheight={
                temporaryWordIndex >= 0
                  ? browserName == "Mobile Safari" ? "100%" : "100%"
                  : attempts?.length > 1
                    ? browserName == "Mobile Safari" ? "93%" : "93%"
                    : browserName == "Mobile Safari" ? "85%" : "85%"
              }
            // initial={false} // Skip the initial animation on mount
            // transition={{ duration: 0.5, ease: "easeInOut" }}
            >
              {/* {!words || wordLoader ? ( */}
              <GeneratingWord
                setBannerImage={setBannerImage}
                setStatusBarColor={setStatusBarColor}
                statusBarColor={statusBarColor}
                wordLoader={wordLoader}
                words={words}
                bannerImageAnimate={bannerImageAnimate}
              />
              {/* ) : ( */}
              <Game
                setTemporaryWord={setTemporaryWord}
                word={word}
                words={words}
                wordCopyForUndo={wordCopyForUndo}
                gameData={gameData}
                finalWord={finalWord}
                initialWord={initialWord}
                action={action}
                handleActionClick={handleActionClick}
                handleAddClick={handleAddClick}
                handleRemoveEmptyAddInput={handleRemoveEmptyAddInput}
                temporaryWordIndex={temporaryWordIndex}
                handleRemoveClick={handleRemoveClick}
                handleChangeClick={handleChangeClick}
                handleInitializeChangeAction={handleInitializeChangeAction}
                handleInitializeMixupAction={handleInitializeMixupAction}
                handleWordLocked={handleWordLocked}
                handleUndoWord={handleUndoWord}
                isWordLocked={isWordLocked}
                attempts={attempts}
                handleVisiblePerformanceScreen={handleVisiblePerformanceScreen}
                activeWordIndex={activeWordIndex}
                validateResponse={validateResponse}
                handleKeyPress={handleKeyPress}
                isKeyboardVisible={isKeyboardVisible}
                actionBtnColor1={actionBtnColor1}
                setActionBtnColor1={setActionBtnColor1}
                actionBtnColor2={actionBtnColor2}
                setActionBtnColor2={setActionBtnColor2}
                actionBtnColor3={actionBtnColor3}
                setActionBtnColor3={setActionBtnColor3}
                actionBtnColor4={actionBtnColor4}
                setActionBtnColor4={setActionBtnColor4}
              // logoImg={logoImg}
              />
              <UtilitiesBtnContainer
                initial={{ opacity:  0 }}
                animate={actionTitleMessageContent}
              >
                <UtilitiesSubContainer>
                  <UtilityText
                    onClick={() => {
                      dispatch(userEventsInitiate({ type: 'restart' }))
                      handleOpenUtilsModal('restart')
                    }}
                  >
                    Restart
                  </UtilityText>
                  &nbsp;{'or'}&nbsp;
                  <UtilityText
                    onClick={() => {
                      dispatch(userEventsInitiate({ type: 'viewPuzzelSolution' }))
                      handleOpenUtilsModal('viewSolution')
                    }}
                  >
                    View Solution
                  </UtilityText>
                </UtilitiesSubContainer></UtilitiesBtnContainer>
              {/* )} */}

              <Result
                isResultVisible={isResultVisible}
                handleVisibleResultScreen={handleVisibleResultScreen}
                handleVisibleBotSolutionScreen={handleVisibleBotSolutionScreen}
                handleCloseResultScreen={() => setIsResultVisible(false)}
                yourResult={yourResult}
                botStepsCount={botStepsCount}
                challengeDifficulty={challengeDifficulty}
                handOpenShareModal={handOpenShareModal}
                handleVisiblePerformanceScreen={handleVisiblePerformanceScreen}
              // payloadData={{
              //   finalWord: finalWord?.join("").toLowerCase() || "",
              //   initialWord: initialWord?.join("").toLowerCase() || "",
              //   currentWord: word?.join("").toLowerCase() || "",
              //   attempt: attempts?.length || 0,
              //   previousWord:
              //     attempts && attempts.length > 0
              //       ? attempts[attempts.length - 1].join("").toLowerCase()
              //       : null,
              //     //   previousWord:
              //     // attempts && attempts.length > 0
              //     //   ? attempts[attempts.length - 1].join("").toLowerCase()
              //     //   : null,
              // }}
              />
              <YourPerformance
                isPerformanceVisible={isPerformanceVisible}
                handleClosePerformanceScreen={() => setIsPerformanceVisible(false)}
                profileData={profileData}
                handleChangeFilterValue={handleChangeFilterValue}
                selectedFilter={selectedFilter}
              />
            </BannerWrapper>

            <BottomCWrapper
              cstmheight={
                temporaryWordIndex >= 0
                  ? browserName == "Mobile Safari" ? "0%" : "0%"
                  : attempts?.length > 1
                    ? browserName == "Mobile Safari" ? "7%" : "7%"
                    : browserName == "Mobile Safari" ? "15%" : "15%"
              }
            ></BottomCWrapper>
            {isKeyboardVisible && (
              <CustomKeyboard
                isBoardDisable={action[0]["mixup"]["isActive"] ? true : false}
                backspaceHidden={true}
                enabledKeys={enabledKeys}
                onKeyPress={handleKeyPress}
              />
            )}
            {<Loader open={yourResultLoader || validateWordLoader} />}
          </ContentWrapper>
        </OnboardingWrapper>

        :
        // Completed game Screen 
        <CompletedGame
          finalWord={finalWord}
          initialWord={initialWord}
          attempts={attempts}
          // your performance props
          isPerformanceVisible={isPerformanceVisible}
          handleVisiblePerformanceScreen={handleVisiblePerformanceScreen}
          handleClosePerformanceScreen={() => setIsPerformanceVisible(false)}
          handleChangeFilterValue={handleChangeFilterValue}
          selectedFilter={selectedFilter}
          gameCompleted={gameCompleted}
          getFirstGame={getFirstGame}
        />
      }
      {
        <ModalForUtilities
          isModalVisible={isUtilsModalVisible}
          handleCloseModal={handleCloseModal}
          title={utilsModalData == 'restart' ? "Restart" : "Give Up?"}
          utilsModalData={utilsModalData}
          handleSubmitClick={() => utilsModalData == 'restart' ? handleRestartGame() : handleViewSolutionClick()}
          handleVisibleBotSolutionScreen={handleVisibleBotSolutionScreen}
          wordId={wordId}
        />
      }
      {
        <ModalShare
          isShareModalVisible={isShareModalVisible}
          handleCloseModal={handleCloseShareModal}
          yourResult={yourResult}
        />

      }
      <BotSolutions
        isResultVisible={isBotSolutionVisible}
        handleCloseBotScreen={() => setIsBotSolutionVisible(false)}
        finalWord={finalWord}
        initialWord={initialWord}
        yourResult={yourResult}
        botSteps={botSteps}
        botStepsCount={botStepsCount}
      />
    </>
  );
};

export default GamePlay;
